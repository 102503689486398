import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 27 21"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M22.395 2.47A21.7 21.7 0 0 0 17.06.842a.08.08 0 0 0-.085.04q-.366.653-.665 1.34a20.2 20.2 0 0 0-5.994 0 13 13 0 0 0-.675-1.34.08.08 0 0 0-.085-.04A21.8 21.8 0 0 0 4.197 2.48a.1.1 0 0 0-.016.018C.783 7.496-.147 12.369.307 17.184a.1.1 0 0 0 .037.062 21.9 21.9 0 0 0 6.544 3.27.09.09 0 0 0 .092-.029 15 15 0 0 0 1.345-2.158.08.08 0 0 0-.045-.113 15 15 0 0 1-2.046-.96.09.09 0 0 1-.037-.068.08.08 0 0 1 .037-.07l.407-.312a.08.08 0 0 1 .085 0 15.7 15.7 0 0 0 6.588 1.445c2.277 0 4.527-.493 6.589-1.445a.08.08 0 0 1 .085 0q.2.164.406.316a.082.082 0 0 1 0 .138q-.983.563-2.046.956a.09.09 0 0 0-.053.083q.001.018.008.033.593 1.12 1.341 2.144.016.021.042.03a.1.1 0 0 0 .05 0 21.8 21.8 0 0 0 6.556-3.271.07.07 0 0 0 .033-.062 21.4 21.4 0 0 0-3.907-14.69.1.1 0 0 0-.023-.013M8.964 14.255c-1.293 0-2.357-1.17-2.357-2.602S7.65 9.047 8.964 9.047s2.374 1.178 2.356 2.602c-.019 1.425-1.045 2.606-2.356 2.606m8.712 0c-1.293 0-2.356-1.17-2.356-2.602s1.04-2.606 2.356-2.606c1.314 0 2.374 1.178 2.356 2.602-.019 1.425-1.034 2.606-2.356 2.606"
    }, null, -1)
  ])))
}
export default { render: render }