import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M15.5 10.634c-.021-2.525 2.07-3.756 2.168-3.811-1.186-1.726-3.023-1.963-3.671-1.98-1.546-.16-3.041.922-3.829.922-.8 0-2.011-.905-3.32-.88-1.681.026-3.252.999-4.112 2.505-1.779 3.075-.453 7.592 1.254 10.08.85 1.218 1.846 2.575 3.15 2.529 1.275-.05 1.754-.812 3.29-.812 1.525 0 1.974.812 3.304.782 1.368-.02 2.232-1.222 3.053-2.449.987-1.396 1.38-2.77 1.398-2.838-.034-.021-2.66-1.024-2.685-4.048m-2.511-7.428c.686-.858 1.156-2.022 1.024-3.206-.99.042-2.236.685-2.947 1.523-.63.74-1.198 1.95-1.05 3.088 1.11.08 2.257-.563 2.973-1.405"
    }, null, -1)
  ])))
}
export default { render: render }