<script setup lang="ts">
const { locale, locales, setLocale } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const target = ref()

const isOpenPopupChangeLanguage = ref(false)
const togglePopup = useToggle(isOpenPopupChangeLanguage)

const { focused } = useFocusWithin(target)
watch(focused, (focused) => {
  if (!focused) togglePopup(false)
})

const currentLocale = computed(
  () => locales.value.find(({ code }) => code === locale.value)!
)

async function onSetLocale(code: string) {
  await setLocale(code)
  isOpenPopupChangeLanguage.value = false
}

const cleanPath = (path: string) => path.replace(/\/demos\//, '/')
</script>

<template>
  <div class="relative flex justify-center" ref="target">
    <button
      class="flex flex-row items-center cursor-pointer capitalize gap-2"
      @click.prevent.stop="togglePopup()"
    >
      <UiLocaleFlagIcon :code="locale" class="w-6 h-6" />
      <span class="uppercase text-[#A3A3A3]">{{ currentLocale.code }}</span>
    </button>

    <div
      @keyup.esc="togglePopup(false)"
      class="absolute md:right-0 z-9 -bottom-1/2 w-50 bg-black rounded-[1.25rem] border-1 border-[#7540EE] h-85 overflow-y-auto"
      :class="isOpenPopupChangeLanguage ? 'visible' : 'invisible'"
    >
      <ul>
        <li
          v-for="{ code, name } in locales"
          :key="code"
          class="p-[0.5rem] flex flex-row items-center cursor-pointer capitalize"
          :class="{
            'text-[#7540EE]': code === locale,
            'font-[Montserrat]': code === 'ru' || code === 'uk',
          }"
        >
          <NuxtLink
            @click.prevent.stop="onSetLocale(code)"
            :title="name"
            :to="cleanPath(switchLocalePath(code))"
            class="inline-flex w-full items-center disabled:op80 hover:op80"
          >
            <span class="bg-[#23262C] p-1 rounded-full w-8 h-8">
              <UiLocaleFlagIcon :code="code" class="w-6 h-6" />
            </span>
            <span class="ml-[0.5rem]">{{ name }}</span>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>
