import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "20",
  height: "20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M1.354 20c-.308-.156-.463-.547-.463-.938V1.484c0-.312.077-.547.155-.859.077-.156.231-.313.386-.469 3.319 3.36 6.561 6.719 9.803 10.078C7.915 13.36 4.674 16.64 1.355 20ZM14.479 6.797c-.927.937-1.93 1.875-2.857 2.89C8.535 6.484 5.37 3.281 2.282.078c0 0 0-.078.077-.078 4.014 2.266 8.028 4.531 12.12 6.797M2.282 20c3.088-3.125 6.253-6.25 9.34-9.375.85.86 1.776 1.719 2.78 2.656-4.016 2.266-8.03 4.531-12.044 6.719zm12.658-7.031c-1.004-.938-1.93-1.875-2.933-2.813l3.01-3.047c.309.157.618.313.926.547.772.469 1.621.86 2.393 1.328.232.078.386.235.54.47q.465.585 0 1.171c-.154.156-.308.313-.54.39-1.08.704-2.238 1.329-3.396 1.954"
    }, null, -1)
  ])))
}
export default { render: render }